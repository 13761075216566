<template>
  <div class="requests">
    <h2>Заявки клиентов</h2>
  </div>
</template>

<script>
export default {
  name: 'Requests',
};
</script>
